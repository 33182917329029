/// <reference types="../utils/grecaptcha/grecaptcha.v2" />

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { InferProps } from 'prop-types';

import { ReCAPTCHA } from 'react-google-recaptcha';

const recaptchaApiScriptId = 'recaptcha-enterprise-api-script';
const recaptchaApiCallbackFnName = 'recaptchaEnterpriseLoadedCallback';

/**
 * Load the Recaptcha v2 Enterprise JavaScript API
 */
function loadRecaptchaApi(): Promise<void> {
	return new Promise((resolve, reject) => {
		const existingScript = document.getElementById(recaptchaApiScriptId);

		if (existingScript) {
			resolve();
		} else {
			const newScript = document.createElement('script');
			newScript.id = recaptchaApiScriptId;
			newScript.src = `https://www.google.com/recaptcha/enterprise.js?onload=${recaptchaApiCallbackFnName}&render=explicit`;

			// @ts-expect-error Dynamically modifying the window object is something TypeScript doesn't like
			window[recaptchaApiCallbackFnName] = () => resolve();

			document.body.appendChild(newScript);

			newScript.addEventListener('error', () => reject());
		}
	});
}

const propTypes = {
	hideCaptcha: PropTypes.bool,
	onChange: PropTypes.func,
	onExpired: PropTypes.func,
	onErrored: PropTypes.func,
};
type CaptchaProps = InferProps<typeof propTypes>;

const Captcha = Object.assign(
	function Captcha(props: CaptchaProps) {

		const siteKey = process.env.NEXT_PUBLIC_CAPTCHA_CLIENTID;
		// `InferProps` allows optional props to be `null`, which is not allowed on `ReCaptcha` props
		const onChange = props.onChange ?? undefined;
		const onExpired = props.onExpired ?? undefined;
		const onErrored = props.onErrored ?? undefined;

		// This `useState` allows us to trigger a re-render when the `Promise` to load the Recaptcha API resolves
		const [isLoaded, setIsLoaded] = useState(false);

		useEffect(
			() => {
				loadRecaptchaApi().then(() => setIsLoaded(true));
			},
			[]
		);

		const hideRecaptcha = !(isLoaded && !props.hideCaptcha && siteKey);
		if (hideRecaptcha) {
			return null;
		}

		const { grecaptcha } = window;
		if (grecaptcha && 'enterprise' in grecaptcha) {
			const { enterprise } = grecaptcha;

			return (
				<ReCAPTCHA
					data-testid="CaptchaExists"
					sitekey={siteKey}
					grecaptcha={enterprise}
					onChange={onChange}
					onExpired={onExpired}
					onErrored={onErrored}
				/>
			);
		} else {
			return null;
		}
	},
	{ propTypes },
);

export default Captcha;
