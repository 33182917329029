/**
 * @typedef SignUpCountry
 * @prop {string} country_code
 * @prop {string} country_name
 * @prop {string} currency_code
 * @prop {string} currency_name
 */

/** @type {SignUpCountry[]} */
export const countries = [
    {
        country_code: 'NZ',
        country_name: 'New Zealand',
        currency_code: 'NZD',
        currency_name: 'New Zealand Dollar',
    },
    {
        country_code: 'AU',
        country_name: 'Australia',
        currency_code: 'AUD',
        currency_name: 'Australian Dollar',
    },
    {
        country_code: 'US',
        country_name: 'United States',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GB',
        country_name: 'United Kingdom',
        currency_code: 'GBP',
        currency_name: 'British Pound',
    },
    {
        country_code: 'CA',
        country_name: 'Canada',
        currency_code: 'CAD',
        currency_name: 'Canadian Dollar',
    },
    {
        country_code: 'AF',
        country_name: 'Afghanistan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AX',
        country_name: 'Aland Islands',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'AL',
        country_name: 'Albania',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'DZ',
        country_name: 'Algeria',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AS',
        country_name: 'American Samoa',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AD',
        country_name: 'Andorra',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'AO',
        country_name: 'Angola',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AI',
        country_name: 'Anguilla',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AQ',
        country_name: 'Antarctica',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AG',
        country_name: 'Antigua and/or Barbuda',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AR',
        country_name: 'Argentina',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AM',
        country_name: 'Armenia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AW',
        country_name: 'Aruba',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AT',
        country_name: 'Austria',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'AZ',
        country_name: 'Azerbaijan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BS',
        country_name: 'Bahamas',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BH',
        country_name: 'Bahrain',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BD',
        country_name: 'Bangladesh',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BB',
        country_name: 'Barbados',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BY',
        country_name: 'Belarus',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BE',
        country_name: 'Belgium',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'BZ',
        country_name: 'Belize',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BJ',
        country_name: 'Benin',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BM',
        country_name: 'Bermuda',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BT',
        country_name: 'Bhutan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BO',
        country_name: 'Bolivia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BA',
        country_name: 'Bosnia and Herzegovina',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BW',
        country_name: 'Botswana',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BV',
        country_name: 'Bouvet Island',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BR',
        country_name: 'Brazil',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'IO',
        country_name: 'British lndian Ocean Territory',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BN',
        country_name: 'Brunei Darussalam',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BG',
        country_name: 'Bulgaria',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BF',
        country_name: 'Burkina Faso',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BI',
        country_name: 'Burundi',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KH',
        country_name: 'Cambodia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CM',
        country_name: 'Cameroon',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CV',
        country_name: 'Cape Verde',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KY',
        country_name: 'Cayman Islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CF',
        country_name: 'Central African Republic',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TD',
        country_name: 'Chad',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CL',
        country_name: 'Chile',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CN',
        country_name: 'China',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CX',
        country_name: 'Christmas Island',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CC',
        country_name: 'Cocos (Keeling) Islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CO',
        country_name: 'Colombia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KM',
        country_name: 'Comoros',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CG',
        country_name: 'Congo',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CD',
        country_name: 'Congo, the Democratic Republic of the',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CK',
        country_name: 'Cook Islands',
        currency_code: 'NZD',
        currency_name: 'New Zealand Dollar',
    },
    {
        country_code: 'CR',
        country_name: 'Costa Rica',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'HR',
        country_name: 'Croatia (Hrvatska)',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CU',
        country_name: 'Cuba',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CY',
        country_name: 'Cyprus',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CZ',
        country_name: 'Czech Republic',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'DK',
        country_name: 'Denmark',
        currency_code: 'DKK',
        currency_name: 'Danish Krone',
    },
    {
        country_code: 'DJ',
        country_name: 'Djibouti',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'DM',
        country_name: 'Dominica',
        currency_code: 'DPO',
        currency_name: 'Dominican Peso',
    },
    {
        country_code: 'DO',
        country_name: 'Dominican Republic',
        currency_code: 'DPO',
        currency_name: 'Dominican Peso',
    },
    {
        country_code: 'EC',
        country_name: 'Ecuador',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'EG',
        country_name: 'Egypt',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SV',
        country_name: 'El Salvador',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GQ',
        country_name: 'Equatorial Guinea',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'ER',
        country_name: 'Eritrea',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'EE',
        country_name: 'Estonia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'ET',
        country_name: 'Ethiopia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'FK',
        country_name: 'Falkland Islands (Malvinas)',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'FO',
        country_name: 'Faroe Islands',
        currency_code: 'DKK',
        currency_name: 'Danish Krone',
    },
    {
        country_code: 'FJ',
        country_name: 'Fiji',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'FI',
        country_name: 'Finland',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'FR',
        country_name: 'France',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'FX',
        country_name: 'France, Metropolitan',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'GF',
        country_name: 'French Guiana',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'PF',
        country_name: 'French Polynesia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TF',
        country_name: 'French Southern Territories',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'GA',
        country_name: 'Gabon',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GM',
        country_name: 'Gambia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GE',
        country_name: 'Georgia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'DE',
        country_name: 'Germany',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'GH',
        country_name: 'Ghana',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GI',
        country_name: 'Gibraltar',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GR',
        country_name: 'Greece',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'GL',
        country_name: 'Greenland',
        currency_code: 'DKK',
        currency_name: 'Danish Krone',
    },
    {
        country_code: 'GD',
        country_name: 'Grenada',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GP',
        country_name: 'Guadeloupe',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'GU',
        country_name: 'Guam',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GT',
        country_name: 'Guatemala',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GG',
        country_name: 'Guernsey',
        currency_code: 'GBP',
        currency_name: 'British Pound',
    },
    {
        country_code: 'GN',
        country_name: 'Guinea',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GW',
        country_name: 'Guinea-Bissau',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'GY',
        country_name: 'Guyana',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'HT',
        country_name: 'Haiti',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'HM',
        country_name: 'Heard and Mc Donald Islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'HN',
        country_name: 'Honduras',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'HK',
        country_name: 'Hong Kong',
        currency_code: 'HKD',
        currency_name: 'Hong Kong Dollar',
    },
    {
        country_code: 'HU',
        country_name: 'Hungary',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'IS',
        country_name: 'Iceland',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'IN',
        country_name: 'India',
        currency_code: 'INR',
        currency_name: 'Indian Rupee',
    },
    {
        country_code: 'ID',
        country_name: 'Indonesia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'IR',
        country_name: 'Iran (Islamic Republic of)',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'IQ',
        country_name: 'Iraq',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'IE',
        country_name: 'Ireland',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'IM',
        country_name: 'Isle of Man',
        currency_code: 'GBP',
        currency_name: 'British Pound',
    },
    {
        country_code: 'IL',
        country_name: 'Israel',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'IT',
        country_name: 'Italy',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'CI',
        country_name: 'Ivory Coast',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'JM',
        country_name: 'Jamaica',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'JP',
        country_name: 'Japan',
        currency_code: 'JPY',
        currency_name: 'Japanese Yen',
    },
    {
        country_code: 'JE',
        country_name: 'Jersey',
        currency_code: 'GBP',
        currency_name: 'British Pound',
    },
    {
        country_code: 'JO',
        country_name: 'Jordan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KZ',
        country_name: 'Kazakhstan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KE',
        country_name: 'Kenya',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KI',
        country_name: 'Kiribati',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KP',
        country_name: "Korea, Democratic People's Republic of",
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KR',
        country_name: 'Korea, Republic of',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KW',
        country_name: 'Kuwait',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KG',
        country_name: 'Kyrgyzstan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LA',
        country_name: "Lao People's Democratic Republic",
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LV',
        country_name: 'Latvia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LB',
        country_name: 'Lebanon',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LS',
        country_name: 'Lesotho',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LR',
        country_name: 'Liberia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LY',
        country_name: 'Libyan Arab Jamahiriya',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LI',
        country_name: 'Liechtenstein',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LT',
        country_name: 'Lithuania',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LU',
        country_name: 'Luxembourg',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'MO',
        country_name: 'Macau',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MK',
        country_name: 'Macedonia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MG',
        country_name: 'Madagascar',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MW',
        country_name: 'Malawi',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MY',
        country_name: 'Malaysia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MV',
        country_name: 'Maldives',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'ML',
        country_name: 'Mali',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MT',
        country_name: 'Malta',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MH',
        country_name: 'Marshall Islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MQ',
        country_name: 'Martinique',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'MR',
        country_name: 'Mauritania',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MU',
        country_name: 'Mauritius',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'YT',
        country_name: 'Mayotte',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MX',
        country_name: 'Mexico',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'FM',
        country_name: 'Micronesia, Federated States of',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MD',
        country_name: 'Moldova, Republic of',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MC',
        country_name: 'Monaco',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'MN',
        country_name: 'Mongolia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'ME',
        country_name: 'Montenegro',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MS',
        country_name: 'Montserrat',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MA',
        country_name: 'Morocco',
        currency_code: 'AED',
        currency_name: 'Emirati Dirham',
    },
    {
        country_code: 'MZ',
        country_name: 'Mozambique',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MM',
        country_name: 'Myanmar',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'NA',
        country_name: 'Namibia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'NR',
        country_name: 'Nauru',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'NP',
        country_name: 'Nepal',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'NL',
        country_name: 'Netherlands',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'AN',
        country_name: 'Netherlands Antilles',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'NC',
        country_name: 'New Caledonia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'NI',
        country_name: 'Nicaragua',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'NE',
        country_name: 'Niger',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'NG',
        country_name: 'Nigeria',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'NU',
        country_name: 'Niue',
        currency_code: 'NZD',
        currency_name: 'New Zealand Dollar',
    },
    {
        country_code: 'NF',
        country_name: 'Norfork Island',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'MP',
        country_name: 'Northern Mariana Islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'NO',
        country_name: 'Norway',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'OM',
        country_name: 'Oman',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PK',
        country_name: 'Pakistan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PW',
        country_name: 'Palau',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PS',
        country_name: 'Palestine, State of',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PA',
        country_name: 'Panama',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PG',
        country_name: 'Papua New Guinea',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PY',
        country_name: 'Paraguay',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PE',
        country_name: 'Peru',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PH',
        country_name: 'Philippines',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PN',
        country_name: 'Pitcairn',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PL',
        country_name: 'Poland',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PT',
        country_name: 'Portugal',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'PR',
        country_name: 'Puerto Rico',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'QA',
        country_name: 'Qatar',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'RE',
        country_name: 'Reunion',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'RO',
        country_name: 'Romania',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'RU',
        country_name: 'Russian Federation',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'RW',
        country_name: 'Rwanda',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'KN',
        country_name: 'Saint Kitts and Nevis',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'LC',
        country_name: 'Saint Lucia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'VC',
        country_name: 'Saint Vincent and the Grenadines',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'BL',
        country_name: 'Saint-Barth\u00e9lemy',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'MF',
        country_name: 'Saint-Martin (French part)',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'WS',
        country_name: 'Samoa',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SM',
        country_name: 'San Marino',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'ST',
        country_name: 'Sao Tome and Principe',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SA',
        country_name: 'Saudi Arabia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SN',
        country_name: 'Senegal',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CS',
        country_name: 'Serbia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SC',
        country_name: 'Seychelles',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SL',
        country_name: 'Sierra Leone',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SG',
        country_name: 'Singapore',
        currency_code: 'SGD',
        currency_name: 'Singapore Dollar',
    },
    {
        country_code: 'SK',
        country_name: 'Slovakia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SI',
        country_name: 'Slovenia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SB',
        country_name: 'Solomon Islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SO',
        country_name: 'Somalia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'ZA',
        country_name: 'South Africa',
        currency_code: 'ZAR',
        currency_name: 'South African Rand',
    },
    {
        country_code: 'GS',
        country_name: 'South Georgia South Sandwich Islands',
        currency_code: 'GBP',
        currency_name: 'British Pound',
    },
    {
        country_code: 'SS',
        country_name: 'South Sudan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'ES',
        country_name: 'Spain',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'LK',
        country_name: 'Sri Lanka',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SH',
        country_name: 'St. Helena',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'PM',
        country_name: 'St. Pierre and Miquelon',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SD',
        country_name: 'Sudan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SR',
        country_name: 'Suriname',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SJ',
        country_name: 'Svalbarn and Jan Mayen Islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SZ',
        country_name: 'Swaziland',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SE',
        country_name: 'Sweden',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'CH',
        country_name: 'Switzerland',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'SY',
        country_name: 'Syrian Arab Republic',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TW',
        country_name: 'Taiwan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TJ',
        country_name: 'Tajikistan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TZ',
        country_name: 'Tanzania, United Republic of',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TH',
        country_name: 'Thailand',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TL',
        country_name: 'Timor-Leste',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TG',
        country_name: 'Togo',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TK',
        country_name: 'Tokelau',
        currency_code: 'NZD',
        currency_name: 'New Zealand Dollar',
    },
    {
        country_code: 'TO',
        country_name: 'Tonga',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TT',
        country_name: 'Trinidad and Tobago',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TN',
        country_name: 'Tunisia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TR',
        country_name: 'Turkey',
        currency_code: 'TRL',
        currency_name: 'Turkish Lira',
    },
    {
        country_code: 'TM',
        country_name: 'Turkmenistan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TC',
        country_name: 'Turks and Caicos Islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'TV',
        country_name: 'Tuvalu',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'UG',
        country_name: 'Uganda',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'UA',
        country_name: 'Ukraine',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'AE',
        country_name: 'United Arab Emirates',
        currency_code: 'AED',
        currency_name: 'Emirati Dirham',
    },
    {
        country_code: 'UM',
        country_name: 'United States minor outlying islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'UY',
        country_name: 'Uruguay',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'UZ',
        country_name: 'Uzbekistan',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'VU',
        country_name: 'Vanuatu',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'VA',
        country_name: 'Vatican City State',
        currency_code: 'EUR',
        currency_name: 'Euro',
    },
    {
        country_code: 'VE',
        country_name: 'Venezuela',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'VN',
        country_name: 'Vietnam',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'VG',
        country_name: 'Virgin Islands (British)',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'VI',
        country_name: 'Virgin Islands (U.S.)',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'WF',
        country_name: 'Wallis and Futuna Islands',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'EH',
        country_name: 'Western Sahara',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'YE',
        country_name: 'Yemen',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'ZM',
        country_name: 'Zambia',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
    {
        country_code: 'ZW',
        country_name: 'Zimbabwe',
        currency_code: 'USD',
        currency_name: 'United States Dollar',
    },
];

/**
 * @typedef SignUpAvailableCurrency
 * @prop {boolean} disabled
 * @prop {null} group Not sure if this property is used anywhere?
 * @prop {boolean} selected
 * @prop {string} text
 * @prop {string} value
 */

/** @type {SignUpAvailableCurrency[]} */
export const availableCurrencies = [
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Choose currency',
        value: '',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: '',
        value: '  ',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'New Zealand Dollar',
        value: 'NZD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Australian Dollar',
        value: 'AUD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'United States Dollar',
        value: 'USD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'British Pound',
        value: 'GBP',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Canadian Dollar',
        value: 'CAD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: '',
        value: ' ',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Angolan Kwanza',
        value: 'AOA',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Argentine Peso',
        value: 'ARS',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Bahraini Dinar',
        value: 'BHD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Barbadian Dollar',
        value: 'BBD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Bosnia and Herzegovina Convertible Marka',
        value: 'BAM',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Brazilian Real',
        value: 'BRL',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Bulgarian Lev',
        value: 'BGN',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Caymanian Dollar',
        value: 'KYD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'CFP Franc',
        value: 'XPF',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Chinese Yuan Renminbi',
        value: 'CNY',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Colombian Peso',
        value: 'COP',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Costa Rican Colón',
        value: 'CRC',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Croatian Kuna',
        value: 'HRK',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Czech Koruna',
        value: 'CZK',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Danish Krone',
        value: 'DKK',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Dominican Peso',
        value: 'DPO',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Dutch Guilder',
        value: 'NLG',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Eastern Caribbean Dollar',
        value: 'XCD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Egyptian Pound',
        value: 'EGP',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Emirati Dirham',
        value: 'AED',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Euro',
        value: 'EUR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Fijian Dollar',
        value: 'FJD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Ghanaian cedi',
        value: 'GHS',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Guatemalan Quetzal',
        value: 'GTQ',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Guernsey Pound',
        value: 'GGP',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Honduran Lempira',
        value: 'HNL',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Hong Kong Dollar',
        value: 'HKD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Hungarian Forint',
        value: 'HUF',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Icelandic Króna',
        value: 'ISK',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Indian Rupee',
        value: 'INR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Indonesian Rupiah',
        value: 'IDR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Israeli Shekel',
        value: 'ILS',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Jamaican Dollar',
        value: 'JMD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Japanese Yen',
        value: 'JPY',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Kenyan Shilling',
        value: 'KES',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Kuwaiti Dinar',
        value: 'KWD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Macanese Pataca',
        value: 'MOP',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Malaysian Ringgit',
        value: 'MYR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Maldivian Rufiyaa',
        value: 'MVR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Mauritius Rupee',
        value: 'MUR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Mexican Peso',
        value: 'MXN',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Moldovan Leu',
        value: 'MDL',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Myanmar Kyat',
        value: 'MMK',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Namibian Dollar',
        value: 'NAD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Netherlands Antillean guilder',
        value: 'ANG',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'New Taiwan Dollar',
        value: 'TWD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Nigerian Naira',
        value: 'NGN',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Norwegian Krone',
        value: 'NOK',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Pakistani Rupee',
        value: 'PKR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Papua New Guinea Kina',
        value: 'PGK',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Peruvian Nuevo Sol',
        value: 'PEN',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Philippine Peso',
        value: 'PHP',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Polish Zloty',
        value: 'PLN',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Qatari Riyal',
        value: 'QAR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Romanian Lei',
        value: 'RON',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Russia Ruble',
        value: 'RUB',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Rwandan Franc',
        value: 'RWF',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Samoan Tala',
        value: 'WST',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Saudi Riyal',
        value: 'SAR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Serbian Dinar',
        value: 'RSD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Singapore Dollar',
        value: 'SGD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'South African Rand',
        value: 'ZAR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Sri Lankan Rupee',
        value: 'LKR',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Swedish Krona',
        value: 'SEK',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Swiss Franc',
        value: 'CHF',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Thai Baht',
        value: 'THB',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Trinidadian Dollar',
        value: 'TTD',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Turkish Lira',
        value: 'TRL',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Ugandan Shilling',
        value: 'UGX',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Uruguayan Peso',
        value: 'UYU',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Vanuatu Vatu',
        value: 'VUV',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Venezuelan Bolívar',
        value: 'VEF',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Vietnamese Dong',
        value: 'VND',
    },
    {
        disabled: false,
        group: null,
        selected: false,
        text: 'Zambian Kwacha',
        value: 'ZMW',
    },
];
